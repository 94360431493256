import Foundation from './inc/foundation';
import 'slick-carousel';
import 'jquery-match-height';

$(document).foundation();

$(window).on('load resize', () => {
  const header = $('.header');
  header.css('--header-height', header.outerHeight() + 'px');
});
/**
 * Add `is-active` class to menu-icon button on Responsive menu toggle
 * And remove it on breakpoint change
 */
$(window)
  .on('toggled.zf.responsiveToggle', function () {
    $('.menu-icon').toggleClass('is-active');
    $(document.documentElement).toggleClass('no-scroll');
  })
  .on('changed.zf.mediaquery', function () {
    $('.menu-icon').removeClass('is-active');
  });

/**
 * Close responsive menu on orientation change
 */
$(window).on('orientationchange', function () {
  setTimeout(function () {
    if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
      $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
    }
  }, 200);
});

const naf = $('section.numbers-and-facts');
if (naf.length) {
  $(window).on('load resize', function () {
    naf.each((i, item) => {
      const wrapper = $(item).find('.numbers-and-facts__container');
      if (!wrapper.hasClass('more-than-three')) return;

      let minHeight;
      const maxHeight = wrapper
        .find('.naf-item')
        .toArray()
        .reduce((acc, cur, idx) => {
          if (idx === 3) {
            minHeight = acc;
          }
          return acc + cur.offsetHeight;
        }, 0);

      wrapper.attr('data-max-height', maxHeight);
      wrapper.attr('data-min-height', minHeight);
      if (Foundation.MediaQuery.is('medium down')) {
        if (!wrapper.hasClass('expanded')) {
          wrapper.height(minHeight);
        }
      } else {
        wrapper.height('auto');
      }
    });
  });
}

const btn = $('.naf-more');
btn.on('click', function () {
  const $this = $(this);
  const wrapper = $this
    .closest('.numbers-and-facts')
    .find('.numbers-and-facts__container');
  const maxHeight = wrapper.attr('data-max-height');
  const minHeight = wrapper.attr('data-min-height');
  const showMore = $this.attr('data-show-more');
  const showLess = $this.attr('data-show-less');
  let h = wrapper.hasClass('expanded') ? minHeight : maxHeight;
  let text = wrapper.hasClass('expanded') ? showMore : showLess;
  wrapper
    .animate({
      height: h,
    })
    .toggleClass('expanded');
  $this.text(text);
});
$(window).on('load resize', function () {
  $('.naf-more-wrapper').toggleClass('hide', Foundation.MediaQuery.is('large'));
  $('body').css('--vw', document.body.clientWidth + 'px');
});

$('.cards-slider-wrap').slick({
  rows: 0,
  slidesToShow: 3,
  arrows: false,
  dots: true,
  infinite: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
});

$('.core-card').on('click', function () {
  if (Foundation.MediaQuery.is('medium down')) {
    const txtWrap = $(this).find('.core-card__content');
    $(this).find('.core-card-button').toggleClass('is-active');
    txtWrap.slideToggle();
  }
});

$(window).on('changed.zf.mediaquery', function () {
  $('.core-card-button').removeClass('is-active');
  const coreContent = $('.core-card__content');
  if (Foundation.MediaQuery.is('large')) {
    coreContent.show();
  } else {
    coreContent.hide();
  }
});

$('.text-gallery-content').each((idx, item) => {
  const mainSlider = $(item).find('.main-slider');
  const navSlider = $(item).find('.nav-slider');
  mainSlider.slick({
    slidesToShow: 1,
    arrows: false,
    rows: 0,
    speed: 1000,
    draggable: false,
    fade: true,
    swipe: false,
    touchMove: false,
    waitForAnimate: false,
    autoplay: true,
    autoplaySpeed: 2000,
    asNavFor: navSlider,
  });
  navSlider
    .slick({
      slidesToShow: 3,
      arrows: false,
      infinite: true,
      rows: 0,
      asNavFor: mainSlider,
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 450,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    })
    .on('setPosition', function (e, slick) {
      if (slick.$slides.length <= slick.options.slidesToShow) {
        // bug with setting .slick-current to first position and empty space at the end of slick-track
        slick.$slideTrack.css('transform', 'none');
      }
    });
});

$('.quote-slider-wrapper, .testimonials-slider-wrap').each((idx, item) => {
  $(item).slick({
    slidesToShow: 1,
    rows: 0,
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          arrows: false,
        },
      },
    ],
  });
});

$('.department-accordion__accordion').on(
  'down.zf.accordion',
  function (e, currentContent) {
    const item = $(currentContent);
    const images = item
      .closest('.department-accordion')
      .find('.department-accordion__image');
    const idx = item.attr('data-index');
    images.removeClass('is-active');
    images
      .filter((i, item) => $(item).attr('data-index') === idx)
      .addClass('is-active');
  }
);

$('.video__placeholder').on('click', function () {
  const video = $(this).prev();
  const videoElem = video.find('video');
  const videoIframe = video.find('iframe');
  videoIframe.attr('src', videoIframe.attr('src') + '&autoplay=1');
  if (videoElem.length) {
    videoElem.get(0).play();
  }
  $(this).addClass('hide');
});

$('.benefits-slider').each((idx, item) => {
  $(item).slick({
    slidesToShow: 5,
    rows: 0,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 790,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  });
});

$('.back-to-top').on('click', function () {
  $('html, body').animate({ scrollTop: 0 });
});

$('.process-item__image').matchHeight();

(function () {
  const header = $('header').find('.header-wrap');
  let scrollPrev = 0;
  $(window).on('scroll', function () {
    const scroll = window.scrollY;
    if (scroll < header.height()) return;
    if (scroll - scrollPrev > 0) {
      header.addClass('hidden');
    } else {
      header.removeClass('hidden');
    }
    scrollPrev = scroll;
  });
})();
